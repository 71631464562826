// extracted by mini-css-extract-plugin
export var billetShadow = "casadesk-module--billet-shadow--6305f";
export var btnTouch = "casadesk-module--btn-touch--a8d12";
export var cInfoYear = "casadesk-module--c-info-year--18271";
export var casadeskFeatures = "casadesk-module--casadesk-features--8ae17";
export var casadeskInfo = "casadesk-module--casadesk-info--09bd4";
export var casadeskInfoContainer = "casadesk-module--casadesk-info-container--d6bad";
export var casadeskInfoGrid = "casadesk-module--casadesk-info-grid--15cbe";
export var casadeskInfoItem = "casadesk-module--casadesk-info-item--43036";
export var casadeskStack = "casadesk-module--casadesk-stack--e691e";
export var casadeskTop = "casadesk-module--casadesk-top--2ff2a";
export var casadeskTopContainer = "casadesk-module--casadesk-top-container--de7d1";
export var colorScheme__background__grayPale = "casadesk-module--colorScheme__background__gray-pale--81166";
export var feat1ImgContainer = "casadesk-module--feat-1-img-container--f023a";
export var feat21Img = "casadesk-module--feat-2-1-img--9ca9a";
export var feat22Img = "casadesk-module--feat-2-2-img--c4e45";
export var feat22ImgContainer = "casadesk-module--feat-2-2-img-container--6b76a";
export var feat2Img = "casadesk-module--feat-2-img--bcef3";
export var feat2ImgContainer = "casadesk-module--feat-2-img-container--66faa";
export var feat31Img = "casadesk-module--feat-3-1-img--1fb8a";
export var feat4Img = "casadesk-module--feat-4-img--f6955";
export var feat4ImgContainer = "casadesk-module--feat-4-img-container--f2102";
export var features1 = "casadesk-module--features-1--d60a8";
export var features1Int = "casadesk-module--features-1-int--086ad";
export var features2 = "casadesk-module--features-2--e096e";
export var features2Int = "casadesk-module--features-2-int--e0215";
export var features3 = "casadesk-module--features-3--5198e";
export var features3Int = "casadesk-module--features-3-int--8059f";
export var features4 = "casadesk-module--features-4--3e140";
export var features4Int = "casadesk-module--features-4-int--d2437";
export var featuresContent = "casadesk-module--features-content--d1b61";
export var featuresList = "casadesk-module--features-list--44a73";
export var featuresListItem = "casadesk-module--features-list-item--d7a0e";
export var featuresMtitle = "casadesk-module--features-mtitle--bdaec";
export var featuresTitle = "casadesk-module--features-title--3d4c4";
export var footerContainer = "casadesk-module--footer-container--9d4ac";
export var header = "casadesk-module--header--63f34";
export var headerContainer = "casadesk-module--header-container--bb58b";
export var headerNav = "casadesk-module--header-nav--4225a";
export var hmbBtn = "casadesk-module--hmb-btn--a655e";
export var icomoon = "casadesk-module--icomoon--0aefd";
export var infoImg = "casadesk-module--info-img--91fe4";
export var itImg = "casadesk-module--it-img--d8413";
export var itImgBox = "casadesk-module--it-img-box--3d83c";
export var itName = "casadesk-module--it-name--61bc5";
export var logo = "casadesk-module--logo--c1ce3";
export var lowercase = "casadesk-module--lowercase--4eedc";
export var mtitle = "casadesk-module--mtitle--9ee63";
export var navItem = "casadesk-module--nav-item--b8fb6";
export var navLink = "casadesk-module--nav-link--b8a32";
export var navList = "casadesk-module--nav-list--03840";
export var open = "casadesk-module--open--03845";
export var sectionTitle = "casadesk-module--section-title--82f06";
export var smw100 = "casadesk-module--smw100--8136c";
export var stackIt = "casadesk-module--stack-it--0e6a4";
export var stackList = "casadesk-module--stack-list--90b9f";
export var svgItem = "casadesk-module--svg-item--36eca";
export var titleBg = "casadesk-module--title-bg--02730";
export var titleContainer = "casadesk-module--title-container--b6bdd";
export var topContent = "casadesk-module--top-content--b04ce";
export var topImg = "casadesk-module--top-img--84b97";
export var topImg1 = "casadesk-module--top-img1--0ead4";
export var topImg2 = "casadesk-module--top-img2--21e88";
export var topImgContainer = "casadesk-module--top-img-container--a234a";
export var transition = "casadesk-module--transition--55b08";
export var transitionBackground = "casadesk-module--transition-background--76e3e";
export var transitionReverse = "casadesk-module--transition-reverse--b61bf";
export var uppercase = "casadesk-module--uppercase--49a65";